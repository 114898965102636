<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; margin-top: 20px">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <label  class="pr-4 inline-block mb-1 text-uppercase font-bold">Job Type</label>
            <div class="flex font-bold py-2">
            <span class="nav-check text-primary text-lg font-bold mr-3"> &check; </span> {{jobDetails.jobType}}
          </div>
        </div>
        <div class="md-layout-item md-size-100 mt-5">
            <label  class="pr-4 inline-block mb-1 text-uppercase font-bold">Salary Type</label>
            <div class="flex font-bold py-2">
            <span class="nav-check text-primary text-lg font-bold mr-3"> &check; </span> {{jobDetails.salaryType}} (${{jobDetails.salaryRangeMin}}-${{jobDetails.salaryRangeMax}})
          </div>
        </div>

        <div class="md-layout-item md-size-70 mt-5">
          <InputFieldComponent
              :requiredLabel="true"
              label="Input Your Expected Salary (USD)"
              class="w-full"
              placeholder="Input here..."
              v-model.trim="$v.form.expected_salary.$model"
              :message="!$v.form.expected_salary.required && $v.form.expected_salary.$dirty ? 'Field is required' : null"
              
          />
        </div>
        <div class="md-layout-item md-size-80 mt-10">
        <FileUploadComponent 
         :requiredLabel="true"
         label="Attach your CV" class="w-full"
         v-model.trim="$v.form.cv.$model"
         path="/trainer_application/cv"
         @input="handleFileUpload"
         :message="!$v.form.cv.required && $v.form.cv.$dirty ? 'Field is required' : null"
         />
        </div>

        <div class="md-layout-item md-size-100 mt-10">
         <TextEditor
         :requiredLabel="true"
         label="Who you have Chosen us"
         class=""
         labelClass="text-uppercase text-sm"
         v-model.trim="$v.form.choosing_reason.$model"
         :message="!$v.form.choosing_reason.required && $v.form.choosing_reason.$dirty ? 'Field is required' : null"               
         />
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent,
  TextEditor,
  FileUploadComponent
 
 
} from "@/components";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Button,
    InputFieldComponent,
    TextEditor,
    FileUploadComponent

  },

  data() {
    return {
      loading: false,
      jobDetails: '',
      form: {
        choosing_reason: '',
        cv: [],
        expected_salary: '',
        job_type: 'Full Time',
        salary_type: 'Monthly'
      }
    }
  },

  methods: {

    formClear(){
      this.form = {
      }
    },
    handleFileUpload(value){
      const path = '/trainer_application/cv/'+value.name;
      this.form.cv = [path.toLowerCase()];

    },
    submit() {
      
      this.$v.$touch();
      if(this.$v.$invalid) return;

      this.form.job_type = this.jobDetails.jobType?this.jobDetails.jobType:this.form.job_type;
      this.form.salary_type = this.jobDetails.salaryType?this.jobDetails.salaryType:this.form.salary_type;

      let formData = this.getLocal('job-application-basic-info');
      
      formData = {
        ...formData,
        ...this.form
      };

      this.setLocal('job-application-basic-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'educationalQualification' });
    },

    clearAll() {
      // this.$emit("clear-all", "");
      this.form = {
        choosing_reason: '',
        cv: [],
        expected_salary: '',
        job_type: 'Full Time',
        salary_type: 'Monthly'
      }

      let formData = this.getLocal('job-application-basic-info');
      console.log(formData);
      formData = {
        ...formData,
        ...this.form
      };

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('job-application-basic-info', formData);
    }
  },
  formClear(){
      this.form = {
        choosing_reason: '',
        cv: '',
        expected_salary: ''
      }
    },
  created() {

    let existData = this.getLocal('job-application-basic-info');

    if (existData.choosing_reason) this.form.choosing_reason = existData.choosing_reason;
    if (existData.cv) this.form.cv = existData.cv;
    if (existData.expected_salary) this.form.expected_salary = existData.expected_salary;
    if (existData) this.jobDetails = existData.jobDetails;
    
  },
  validations: {
    form: {
      choosing_reason: {required},
      cv: {required},
      expected_salary: {required}
    }
  }
}
</script>
<style scoped>
.nav-check{
  display: block!important;
}
</style>